<template>
    <div>
        <PageLoader :storage="appLoading" />
        <ServerError v-if="ServerError" />
        <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
            <v-layout wrap justify-center>
                <v-flex text-left class="align-self-center">
                    <span style="color: #fff">{{ msg }}</span>
                </v-flex>
                <v-flex text-right>
                    <v-btn small :ripple="false" text @click="showSnackBar = false">
                        <v-icon style="color: #fff">mdi-close</v-icon>
                    </v-btn>
                </v-flex>
            </v-layout>
        </v-snackbar>
        <!-- <v-layout wrap justify-center pt-2>
            <v-flex xs12>
                <v-layout wrap justify-start>
                    <v-flex v-for="(card, index) in cards" :key="index" xs12 md4 sm4 lg3 pa-3>
                        <v-card>
                            <v-layout wrap justify-center>
                                <v-flex xs12 pa-10>
                                    <v-layout wrap>
                                        <v-flex xs9 lg9>
                                            <v-layout wrap justify-end>
                                                <v-flex xs12 class="text-start"><span class="detailshead">{{
                                                    card.title
                                                        }}</span></v-flex>
                                                <v-flex xs12 class="text-start value" pt-2>{{
                                                    card.value
                                                    }}</v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs3 lg3>
                                            <v-img :src="card.icon"></v-img>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout wrap justify-end pt-8>
                                        <v-flex xs12 class="text-end">
                                            <span>
                                                <span style="
                              color: #20b659;
                              font-size: 12px;
                              cursor: pointer;
                              font-family: poppinsregular;
                              font-weight: 500;
                            " @click="redirectToPage(card.action, card.title)">{{ card.action }}
                                                    <span>
                                                        <v-icon small
                                                            color="#20B659">mdi-arrow-right</v-icon></span></span>
                                            </span>
                                        </v-flex>
                                    </v-layout>
                                </v-flex>
                            </v-layout>
                        </v-card>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <v-layout wrap justify-start>
            <v-flex xs12>
                <v-layout wrap justify-start>
                    <v-flex xs12 lg7>
                        <v-layout wrap justify-start>
                            <v-flex xs12 pa-3>
                                <v-card elevation="1">
                                    <v-layout wrap justify-start pa-5>
                                        <v-flex xs12>
                                            <v-layout wrap justify-start>
                                                <v-flex xs12 lg9>
                                                    <v-layout wrap justify-start>
                                                        <v-flex>
                                                            <span class="reportone">Total Cases</span>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                                <v-flex xs12 lg3>
                                                    <v-select outlined dense :hide-details="true" :items="yearitems"
                                                        v-model="lineyeardata"></v-select>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 pt-2>
                                            <v-layout wrap justify-start>
                                                <v-flex xs12 v-if="flag1">
                                                    <apexchart type="bar" height="262" :options="chartOptions3"
                                                        :series="series3"></apexchart>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start pt-4>
                            <v-flex xs12 pa-3>
                                <v-card elevation="1">
                                    <v-layout wrap justify-start pa-5>
                                        <v-flex xs12>
                                            <v-layout wrap justify-start>
                                                <v-flex xs12 pa-3>
                                                    <span class="reportone">Top Rescuers of the Year {{ currentYear
                                                        }}</span>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 pt-2>
                                            <v-layout wrap justify-start>
                                                <v-flex xs12 sm4 md4 lg4 v-for="item in topusers" :key="item.name">
                                                    <v-layout wrap justify-start pa-3>
                                                        <v-flex xs12 lg10 v-if="item && item.user && item.user.name"
                                                            class="rescuername">
                                                            {{ item.user.name }}
                                                        </v-flex>
                                                        <v-flex xs12 lg2 class="text-left rescuervalue">
                                                            <span v-if="item && item.user && item.count">
                                                                {{ item.count }}
                                                            </span>
                                                            <span v-else>0</span>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-layout wrap justify-end>
                                                <v-flex xs12 pa-3 class="text-end">
                                                    <span class="viewmore" @click="redirectToUsersDetails()"
                                                        style="cursor: pointer">View More</span>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                    <v-flex xs12 lg5>
                        <v-layout wrap justify-center>
                            <v-flex xs12 pa-3>
                                <v-card elevation="1" height="630px">
                                    <v-layout wrap justify-center pa-5>
                                        <v-flex xs12>
                                            <v-layout wrap justify-center>
                                                <v-flex xs12>
                                                    <v-layout wrap justify-center>
                                                        <v-flex xs12 class="text-center">
                                                            <span class="reportone text-center">Top Common Snake
                                                                Species</span>
                                                        </v-flex>
                                                    </v-layout>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 pt-10>
                                            <v-layout wrap justify-start>
                                                <v-flex xs12 v-if="flag">
                                                    <apexchart width="480px" height="300px" type="donut"
                                                        :options="options" :series="series"></apexchart>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                        <v-flex xs12 pt-2>
                                            <v-layout wrap justify-center>
                                                <v-flex xs12 class="text-center">
                                                    <span @click="redirectToSnakeDetails()" class="viewdetails">View
                                                        Details</span>
                                                </v-flex>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-card>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout> -->
        <v-layout wrap justify-center>
            <!-- <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex
              v-for="(date, index) in dates"
              :key="index"
              xs12
              md4
              sm4
              lg3
              pa-3
            >
              <v-card>
                <v-layout wrap justify-center pa-5>
                  <v-flex xs6 class="text-start">
                    <v-icon color="red">mdi-calendar</v-icon
                    ><span style="font-size: 17px; color: red">{{
                      date.year
                    }}</span>
                  </v-flex>
                  <v-flex xs6 class="text-end value">
                    <span style="font-size: 20px">{{ date.count }}</span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-flex> -->
        </v-layout>
        <v-layout wrap justify-center>
            <!-- <v-flex xs12>
          <v-layout wrap justify-start>
            <v-flex xs12 md4 sm4 lg4 pa-3>
              <v-card elevation="1">
                <v-layout wrap justify-center>
                  <v-flex xs12 pa-5>
                    <v-layout wrap pb-2>
                      <v-flex xs1>
                        <span
                          ><v-icon small color="yellow"
                            >mdi-rotate-right-variant</v-icon
                          ></span
                        >
                      </v-flex>
                      <v-flex xs11>
                        <span class="detailshead">Most Common Snakes - 2024</span>
                      </v-flex>
                    </v-layout>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 px-5>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr v-for="item in topsnakes" :key="item.name">
                            <td class="listvalue">{{ item.snake.name }}</td>
                            <td>{{ item.count }}</td>
                          </tr>
                        </tbody>
                      </template>
</v-simple-table>
<v-divider></v-divider>
</v-flex>
<v-flex xs12 pa-5>
    <v-layout wrap justify-center>
        <v-flex xs12 class="text-center">
            <span class="viewmore" @click="redirectToSnakeDetails()" style="cursor: pointer">View More</span>
        </v-flex>
    </v-layout>
</v-flex>
</v-layout>
</v-card>
</v-flex>
<v-flex xs12 md4 sm4 lg4 pa-3>
    <v-card elevation="1">
        <v-layout wrap justify-center>
            <v-flex xs12 pa-5>
                <v-layout pb-2>
                    <v-flex xs1>
                        <span><v-icon small color="green">mdi-account-check</v-icon></span>
                    </v-flex>
                    <v-flex xs11>
                        <span class="detailshead">Top Rescuers of {{ currentMonth }}</span>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 px-5>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                          <tr v-for="item in topusers" :key="item.name">
                            <td class="listvalue">{{ item.user.name }}</td>
                          </tr>
                        </tbody>
                      </template>
                </v-simple-table>
                <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 pa-5>
                <v-layout wrap justify-center>
                    <v-flex xs12 class="text-center">
                        <span class="viewmore" @click="redirectToUsersDetails()" style="cursor: pointer">View
                            More</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card>
</v-flex>
<v-flex xs12 md4 sm4 lg4 pa-3>
    <v-card elevation="1">
        <v-layout wrap justify-center>
            <v-flex xs12 pa-5>
                <v-layout pb-2>
                    <v-flex xs1>
                        <span><v-icon small color="blue">
                                mdi-human-child</v-icon></span>
                    </v-flex>
                    <v-flex xs11>
                        <span class="detailshead">Top Rescuers of {{ currentYear }}</span>
                    </v-flex>
                </v-layout>
                <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 px-5>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                          <tr v-for="item in topusers" :key="item.name">
                            <td class="listvalue">{{ item.user.name }}</td>
                          </tr>
                        </tbody>
                      </template>
                </v-simple-table>
                <v-divider></v-divider>
            </v-flex>
            <v-flex xs12 pa-5>
                <v-layout wrap justify-center>
                    <v-flex xs12 class="text-center">
                        <span class="viewmore" @click="redirectToUsersyearDetails()" style="cursor: pointer">View
                            More</span>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-card>
</v-flex>
</v-layout>
</v-flex> -->
        </v-layout>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.toLocaleString("default", {
            month: "long",
        });
        return {
            currentYear,
            currentMonth,
            appLoading: false,
            ServerError: false,
            showSnackBar: false,
            timeout: 5000,
            msg: null,
            pages: 0,
            count: 20,
            cards: [
                {
                    title: "Total Users",
                    value: 0,
                    action: "View Map",
                    icon: require("@/assets/images/dash1.png"),
                    color: "green",
                },
                {
                    title: "Snake Rescuers",
                    value: 0,
                    action: "View Map",
                    icon: require("@/assets/images/dash2.png"),
                    color: "blue",
                },
                {
                    title: "Snakes Rescued",
                    value: 0,
                    action: "View Map",
                    icon: require("@/assets/images/dash3.png"),
                    color: "red",
                },
                {
                    title: "Identification Help",
                    value: 0,
                    action: "View Details",
                    icon: require("@/assets/images/dash4.png"),
                    color: "yellow",
                },
                {
                    title: "Listed Hospitals",
                    value: 0,
                    action: "View Map",
                    icon: require("@/assets/images/dash5.png"),
                    color: "red",
                },
                {
                    title: "Snake Deaths",
                    value: 0,
                    action: "View Details",
                    icon: require("@/assets/images/dash6.png"),
                    color: "yellow",
                },
                {
                    title: "Listed Snakes",
                    value: 0,
                    action: "View Details",
                    icon: require("@/assets/images/dash7.png"),
                    color: "light-green",
                },
                {
                    title: "Public users",
                    value: 0,
                    action: "View Details",
                    icon: require("@/assets/images/dash8.png"),
                    color: "purple",
                },
            ],
            dates: [],
            details: [
                { title: "Most Common Snakes - 2024", icon: "mdi-snake" },
                { title: "Top Rescuers of July", icon: "mdi-snake" },
                { title: "Top Rescuers of 2024", icon: "mdi-snake" },
            ],
            topsnakes: [],
            topusers: [],
            chartOptions3: {
                chart: {
                    height: 262,
                    type: "bar",
                },
                plotOptions: {
                    bar: {
                        columnWidth: "45%",
                        distributed: true,
                    },
                },
                dataLabels: {
                    enabled: true,
                },
                legend: {
                    show: false,
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            fontSize: "12px",
                        },
                    },
                    title: {
                        text: "Year",
                    },
                },
                yaxis: {
                    title: {
                        text: "Count",
                    },
                },
            },
            series3: [
                {
                    name: "Count",
                    data: [],
                },
            ],
            yearitems: ["Last 7 Years", "Last 5 Years"],
            lineyeardata: "Last 7 Years",
            options: {
                chart: {
                    type: "donut",
                },
                labels: [], // For the animal names
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200,
                            },
                            legend: {
                                position: "bottom",
                            },
                        },
                    },
                ],
            },
            series: [],
            flag: false,
            flag1: false,
        };
    },
    beforeMount() {
        this.countData();
        this.yearData();
        // this.animalcountData();
    },
    methods: {
        redirectToSnakeDetails() {
            this.$router.push({ path: "/topcommonsnakes" });
        },
        animalcountData() {
            this.appLoading = true;
            axios({
                method: "GET",
                url: "/hwc/animalsummary/reports",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        const animalReportSummary = response.data.data.animalReportSummary;
                        this.series = animalReportSummary.map((item) => item.totalReports);
                        this.options.labels = animalReportSummary.map((item) => item.name);
                        this.totalconflictdata = response.data.data.totalReports;
                        this.flag = true
                        this.appLoading = false;
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.appLoading = false;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    this.ServerError = true;
                    console.log(err);
                });
        },
        redirectToPage(action, title) {
            if (title === "Total Users") {
                this.$router.push({ path: "/dmousermap" });
            } else if (title === "Snake Rescuers") {
                this.$router.push({ path: "/dmorescuermap" });
            } else if (title === "Snakes Rescued") {
                this.$router.push({ path: "/dmosnakerescued" });
            }
            // else if (title === "Identification Help") {
            //   this.$router.push({ path: "/officeidentificationhelp" });
            // } else if (title === "Listed Hospitals") {
            //   this.$router.push({ path: "/officehospitalMap" });
            // }
            // else if (title === "Snake Deaths") {
            //   this.$router.push({ path: "/officesnakebiteDeath" });
            // }
            // else if (title === "Listed Snakes") {
            //   this.$router.push({ path: "/officelistedSnakes" });
            // }
        },

        redirectToUsersDetails() {
            this.$router.push({ path: "/topUsers" });
        },
        redirectToUsersyearDetails() {
            this.$router.push({ path: "/topUsersyear" });
        },
        countData() {
            this.appLoading = true;
            axios({
                method: "GET",
                url: "/user/stats",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        {
                            this.cards[0].value = response.data.data.count_total_users;
                            this.cards[1].value = response.data.data.count_total_rescuer;
                            this.cards[2].value = response.data.data.count_total_locations;
                            this.cards[3].value = response.data.data.count_identify;
                            this.cards[4].value = response.data.data.count_hospital;
                            this.cards[5].value = response.data.data.count_management;
                            this.cards[6].value = response.data.data.count_snakes;
                            this.cards[7].value = response.data.data.count_users;
                            this.topsnakes = response.data.data.topsnakes;
                            const topcommonsnakesSummary = response.data.data.topsnakes;
                            this.series = topcommonsnakesSummary.map((item) => item.count);
                            this.options.labels = topcommonsnakesSummary.map((item) => item.snake.name);
                            this.flag = true;
                            this.topusers = response.data.data.topusers;
                            this.appLoading = false;
                        }
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.appLoading = false;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    (this.ServerError = true), console.log(err);
                });
        },
        yearData() {
            this.appLoading = true;
            axios({
                method: "GET",
                url: "/user/yearwise",
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        {
                            const yearwiseData = response.data.data;
                            this.chartOptions3.xaxis.categories = yearwiseData.map(item => item.year);
                            this.series3[0].data = yearwiseData.map(item => item.count);
                            this.flag1 = true;
                            this.dates = response.data.data;
                            this.appLoading = false;
                        }
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        this.appLoading = false;
                    }
                })
                .catch((err) => {
                    this.appLoading = false;
                    (this.ServerError = true), console.log(err);
                });
        },
    },
};
</script>
<style>
.detailshead {
    font-family: poppinsregular;
    font-size: 14px;
    font-weight: 800;
    line-height: 18px;
}

.value {
    font-family: poppinsregular;
    font-size: 25px;
    font-weight: 700;
    line-height: 18px;
}

.listvalue {
    font-family: poppinsbold;
    font-size: 14px;
}

.sarpa {
    font-family: poppinsmedium;
    font-size: 20px;
    font-weight: 500px;
}

.viewmore {
    font-family: poppinsregular;
    font-size: 13px;
    text-decoration: underline;
}

.reportone {
    font-family: poppinsregular;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
}

.rescuername {
    font-family: poppinsregular;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
}

.rescuervalue {
    font-family: poppinsregular;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    color: #58d062;
}

.viewdetails {
    font-family: poppinsregular;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    color: #20B659;
    text-decoration: underline;
    cursor: pointer;
}
</style>